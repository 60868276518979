import React, { useEffect, useState } from "react";
import API from "../../api";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  DatePicker,
  Space,
  message,
  Badge,
  Steps,
  Typography,
  Popconfirm,
  Avatar,
  Divider,
  Tooltip,
  PageHeader,
} from "antd";

import { Modal } from "antd"; // Sử dụng Ant Design Modal
import {
  SaveOutlined,
  DeleteOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  FileAddTwoTone,
  PrinterOutlined,
  SmileOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";

import moment from "moment";

const { Text } = Typography;
const { Step } = Steps;
const nghilucs = [
  { value: 1, label: "Sáng" },
  { value: 2, label: "Chiều" },
];
const tinhChuNhats = [
  { value: 0, label: "Không tính Chủ Nhật" },
  { value: 1, label: "Tính Chủ Nhật" },
];
const loainghis = [
  {
    value: 1,
    label: "Nghỉ phép năm",
  },
  {
    value: 3,
    label: "Nghỉ hưởng lương (tang, cưới..)",
  },
  {
    value: 5,
    label: "Nghỉ ốm ( hưởng BHXH)",
  },

  {
    value: 2,
    label: "Nghỉ thai sản",
  },
  {
    value: 4,
    label: "Nghỉ không lương",
  },
  {
    value: 6,
    label: "Đi công tác, đào tạo",
  },
];

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function NghiPhep() {
  /* Data load dùng chung
   **/
  const profile = JSON.parse(localStorage.getItem("auth"));

  /* Data load dùng chung
   **/
  const dateFormat = "DD/MM/YY";
  //const datetimeFormat = "YYYY-MM-DD HH:mm:ss";
  const [form] = Form.useForm();
  /**States */

  const [nhanviensMatch, setNhanviensMatch] = useState([]); // dùng cho select nhân viên
  const [dataForm, setDataForm] = useState(); // laod tất cả dữ liệu theo user
  const [dataFormSigns, setDataFormSigns] = useState([]); // laod tất cả thông tin chữ ký
  const [dataFormSignsActive, setDataFormSignsActive] = useState([]); // laod tất cả thông tin chữ ký
  const [change, setChange] = useState(false); // theo dõi dữ liệu có thay đổi ko
  const [indexCurrent, setIndexCurrent] = useState(0); //chỉ mục đang chọn
  const [record, setRecord] = useState(0); // dữ liệu đang chọn
  const [disable, setDisable] = useState(false); // khóa nút lui
  const [disable2, setDisable2] = useState(false); // khóa nút tới
  const [disable3, setDisable3] = useState(false); // khóa nút xóa
  const [disable4, setDisable4] = useState(false); // khóa nút xóa
  const [lookAllInput, setLookAllInput] = useState(true); // khóa nút xóa
  const [showLucnghi, setShowLucnghi] = useState(false); //ẩn
  const [showAdd, setShowAdd] = useState(true);
  const [thongtinDon, setThongTinDon] = useState([]);
  const [nhanvienAll, setNhanvienAll] = useState([]); // dùng cho select nhân viên
  const [filter, setFilter] = useState(profile.idNhanvien);
  const [profileInfo, setProfileInfo] = useState([]);
  const [lydoNghiPhep, setLydoNghiPhep] = useState("");
  const [soNgayPhep, setSoNgayPhep] = useState("");

  const [isPrintPreviewOpen, setIsPrintPreviewOpen] = useState(false);

  const handleShowPrintPreview = () => {
    setIsPrintPreviewOpen(true);
  };

  const handleClosePrintPreview = () => {
    setIsPrintPreviewOpen(false);
  };

  const handlePrint = () => {
    if (record.key === 0) {
      alert("Bạn phải nhấn nút lưu thông tin trước khi in");
      return;
    }
    let loainghiphep = "";
    switch (record.loaiNgaynghi) {
      case 1:
        loainghiphep = "Nghỉ phép năm";
        break;
      case 2:
        loainghiphep = "Nghỉ thai sản";
        break;
      case 3:
        loainghiphep = "Nghỉ hưởng lương (tang, cưới..)";
        break;
      case 4:
        loainghiphep = "Nghỉ không lương";
        break;
      case 5:
        loainghiphep = "Nghỉ ốm ( hưởng BHXH)";
        break;
      case 6:
        loainghiphep = "Đi công tác, đào tạo";
        break;

      default:
        break;
    }
    let content = `
    <table>
    <tr>
    <td>
    <img width=250 height=70
    src="/logohmdn.png"
    </td>
    <td style="text-align: center; vertical-align: middle;">
    <h1> ĐƠN XIN NGHỈ PHÉP</h1>
    <h4>Ngày làm đơn:  ${record.ngaylamdon}. Mã đơn: ${record.key}</h4>
    </td>
    </tr>
    </table>

    <table style="border-collapse: collapse; width: 100%;" border="1">
	<tbody>
		<tr>
			<td><strong>Họ tên</strong></td>
			<td>${profileInfo[0].NickName}</td>
			<td ><strong>Phòng/Khoa</strong></td>
			<td>${profileInfo[0].TenPhongBan}</td>
		</tr>
		<tr>
			<td><strong>Mã nhân viên</strong></td>
			<td>${profileInfo[0].MaNV}</td>
			<td >
			<strong>Chức danh</strong>
			</td>
			<td >${profileInfo[0].TenChucDanh}</td>
		</tr>
		<tr>
			<td><strong>CBQL trực tiếp</strong></td>
			<td>${profileInfo[0].NguoiQuanLy}</td>
			<td ><strong>Số điện thoại </strong></td>
			<td >${profileInfo[0].Mobile}</td>
		</tr>
    <tr>
    <td><strong>Loại HĐLĐ </strong></td>
    <td>${profileInfo[0].LoaiHopDong}</td>
    <td > <strong>Nơi nghỉ phép</strong></td>
    <td ></td>
  </tr>

	</tbody>
</table>
<p>THÔNG TIN ĐƠN</p>
<table style="border-collapse: collapse; width: 100%;" border="1">
<tr>
<td><strong>Thời gian xin nghỉ </strong></td>
<td>
     Số ngày nghỉ:  ${record.soNgaynghi}, nghỉ từ ngày ${moment(
      record.nghiTungay
    ).format(dateFormat)}, đến ngày ${moment(
      moment(record.nghiTungay).add(
        record.soNgaynghi <= 1 ? 0 : record.soNgaynghi - 1,
        "days"
      )
    ).format(dateFormat)}
     </td>
</tr>
<tr>
<td>
<strong>Tổng số ngày phép đã nghỉ</strong> 
(Tính cả số ngày nghỉ lần này):
${thongtinDon[0].SoNgayTinhPhep}
</td>
<td>
<strong>Tổng số ngày phép tồn trong năm</strong>
(Tính cả số ngày nghỉ lần này):
${thongtinDon[0].PhepTon}
</td>
</tr>

<tr>
<td > <strong>Loại nghỉ phép</strong></td>
<td >${loainghiphep}</td>
</tr>
<tr>
<td > <strong>Lý do nghỉ phép</strong></td>
<td >${record.lydoNghi}</td>
</tr>

</table>
<p></p>
<table style="border-collapse: collapse; width: 100%;" border="1">
<tr>
<td ><strong>Người nhận bàn giao 1</strong>
(Ký và ghi rõ họ tên)

<br>
<br>
<br>
<br>

<strong>Tên: </strong><br>
<strong>Bộ phận: </strong>
</td>
<td ><strong>Người nhận bàn giao 2 (nếu có)</strong>
(Ký và ghi rõ họ tên)

<br>
<br>
<br>
<br>
<strong>Tên: </strong><br>
<strong>Bộ phận: </strong>
</td>

</tr>
</table>
<p>XÁC NHẬN VÀ PHÊ DUYỆT</p>
<table style="border-collapse: collapse; width: 100%;" border="1">
<tr>
    <td>
    <strong style="text-align: center"> Nhân viên ký, ghi rõ họ tên</strong>
    <br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
    </td>
    <td style="text-align: center">
    <strong>Ý  kiến của Quản lý trực tiếp</strong>
    <br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
    </td>
    <td style="text-align: center">
    <strong>Ý  kiến của Trưởng khoa/phòng</strong>
    <br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
    </td>
    <td style="text-align: center">
    <strong>Ý  kiến của ĐDT Bệnh viện</strong>
    <br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
    </td>

</tr>
<tr>
<td style="text-align: center">
<strong>Xác nhận của TP nhân sự</strong> <br>

<br>
<br>
<br>
<br>
<br>
</td>
<td style="text-align: center" >
<strong>Chấp thuận của GĐ chuyên môn</strong><br>
<br>
<br>
<br>
<br>
<br>
</td>
<td style="text-align: center" colspan=2>
<strong>Chấp thuận của GĐ bệnh viện</strong>
<br>
<br>
<br>
<br>
<br>
<br>
</td>
</tr>
<tr>
    <td style="text-align: center"><strong>ĐỖ THỊ DIỄM CHÂU</strong>
    </td>
    <td style="text-align: center"><strong>TRẦN MINH NGHĨA</strong>
    </td>
    <td style="text-align: center" colspan=2><strong>TRƯƠNG NGUYỄN THOẠI NHÂN</strong>
    </td>
</tr>
</table>
<p><strong>Ghi chú:</strong> 
Xin chấp thuận của BGĐ đối với: nhân viên nghỉ >=2 ngày và Bác sĩ + Trưởng khoa/phòng nghỉ >=1 ngày.
</p>  
    `;
    const previewWindow = window.open("", "_blank");
    previewWindow.document.write(content);
    previewWindow.document.close();
    previewWindow.print();
  };

  /**useEffect */
  useEffect(() => {
    let content = { storename: "nhanvienAll", param: "" };
    const loadNhanvienAll = async () => {
      let response = await API.post("/databy", content);
      setNhanvienAll(response.data);
    };
    loadNhanvienAll();
  }, []); // khi record edit thay đổi sẽ cập nhật lại data
  useEffect(() => {
    const loadDataForm = async () => {
      try {
        let response = await API.get(
          "/getDonNghiPhepByIdNhanVien?idNhanvien=" + filter
        );
        setDataForm(response.data[0]);
        setDataFormSigns(response.data[1]);
        setThongTinDon(response.data[2]);
        setProfileInfo(response.data[3]);
        if (response.data[0][indexCurrent]) {
          setRecord({
            ...response.data[0][indexCurrent],
            nghiTungay: moment(response.data[0][indexCurrent].nghiTungay),
          });
        }

        setChange(false);
      } catch (error) {
        message.error("Lỗi không lấy được dữ liệu nguồn " + error);
      }
    };
    loadDataForm();
  }, [change, indexCurrent, filter]);

  useEffect(() => {
    //form.resetFields();
    form.setFieldsValue(record);

    if (dataFormSigns) {
      let signs = dataFormSigns.filter(function (e) {
        return e.idDonNghiPhep === record.key && e.index >= 0;
      });
      setDataFormSignsActive(signs);
    }

    if (dataForm) {
      if (
        indexCurrent + 1 === dataForm.length ||
        dataForm.length === 0 ||
        dataForm.length === 1 ||
        record.index === 0
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
      if (indexCurrent === 0) {
        setDisable2(true);
      } else {
        setDisable2(false);
      }

      if (record.khoa === 1 || record.key === 0 || dataForm.length === 0) {
        setDisable3(true);
      } else {
        setDisable3(false);
      }

      if (record.khoa === 1 || record.signNum >= 1) {
        setDisable4(true);
      } else {
        setDisable4(false);
      }
    }
  }, [record, dataForm, dataFormSigns, form, indexCurrent]);

  useEffect(() => {
    if (nhanvienAll.length > 0 && profileInfo[0]) {
      let nhanviens = nhanvienAll.filter(function (nhanvien) {
        if (profileInfo[0].ID_PhongBan === nhanvien.idPhongBan) {
        }
        return (
          (profileInfo[0].ID_PhongBan === nhanvien.idPhongBan ||
            profileInfo[0].ID_PhongBan_QuanLy === nhanvien.idPhongBan ||
            profileInfo[0].ID_PhongBan_QuanLy1 === nhanvien.idPhongBan ||
            profileInfo[0].ID_PhongBan_QuanLy2 === nhanvien.idPhongBan ||
            profileInfo[0].ID_PhongBan === nhanvien.ID_PhongBan1 ||
            profileInfo[0].ID_PhongBan === nhanvien.ID_PhongBan2) &&
          profileInfo[0].idNhanvien !== parseInt(nhanvien.value)
        );
      });

      setNhanviensMatch(nhanviens);
    }
  }, [filter, profileInfo, nhanvienAll]);
  /**End useEffect */

  /**Functions */
  const onFinish = (values) => {
    if (
      checkLeaveDate(
        moment(values.nghiTungay).format("YYYY-MM-DD"),
        values.soNgaynghi
      ) === true &&
      values.loaiNgaynghi === 1
    ) {
      message.warning(
        "Đơn nghỉ phép giao nhau với ngày 31/3 hoặc 1/4. Hãy tách 2 đơn riêng biệt, 1 đơn nghỉ  đến 31/3 và  1 đơn nghỉ từ 1/4"
      );
    } else {
      if (record.key === 0 || record.tpnsState === "1") {
        //add new
        let data = JSON.stringify({
          ...values,
          idNhanvien: profileInfo[0].idNhanvien,
          tkpPheduyet: profileInfo[0].ID_TruongKhoaPhong,
          tpnsPheduyet: profileInfo[0].ID_TruongPhongNhanSu,
          bgdPheduyet: profileInfo[0].ID_GiamDoc,
          ddtBvPheduyet: profileInfo[0].ID_DieuDuongTruongBV,
          ddtPheduyet: profileInfo[0].ID_NguoiQuanLy,
          loaiDon: profileInfo[0].PhanLoai,
          TruongKhoaPhong: profileInfo[0].TruongKhoaPhong,
          DD_KTV_Truong: profileInfo[0].DD_KTV_Truong,
          nghiTungay: moment(values.nghiTungay).format("YYYY-MM-DD"),
        });

        API.post("/donnghiphep", data)
          .then((res) => {
            if (res.data[0].id !== 0) {
              message.success(
                "Tạo đơn thành công, vui lòng chờ cấp trên phê duyệt"
              );
              setChange(true); //dữ liệu đã thay đổi, cập nhật lại cho effect dep
            } else {
              message.warning(
                profileInfo[0].NickName +
                  ` Muốn gửi thêm đơn mới thì đơn gần nhất của bạn phải có ít nhất một người phê duyệt (Trưởng KP, Điều dưỡng trưởng, Điều dưỡng trưởng Bv, TPNS )`
              );
              setChange(true);
            }
          })
          .catch(function (error) {
            if (error.response) {
              message.warning("Có lỗi xảy ra");
            }
          });
      } else {
        save({
          key: record.key,
          _soNgaynghi: record.soNgaynghi,
          _nghiTungay: moment(record.nghiTungay).format("YYYY-MM-DD"),
          _loaiNgaynghi: record.loaiNgaynghi,
          _lydoNghi: lydoNghiPhep,
          _nguoiBangiaoA: record.nguoiBangiaoA,
          _nguoiBangiaoB: record.nguoiBangiaoB,
          _nghiLuc: record.nghiLuc,
          _tinhChuNhat: record.tinhChuNhat,
        });
      }
    }
  };
  function handleChange(value) {
    setRecord({
      ...record,
      loaiNgaynghi: value,
    });
  }

  const handleOnClickBack = () => {
    setIndexCurrent(indexCurrent + 1);
    //console.log(dataForm);
    setRecord({
      ...dataForm[indexCurrent + 1],
      nghiTungay: moment(dataForm[indexCurrent + 1].nghiTungay),
    });
    //console.log(record.index, indexCurrent);
  };
  const handleOnClickForward = () => {
    setIndexCurrent(indexCurrent - 1);
    setRecord({
      ...dataForm[indexCurrent - 1],
      nghiTungay: moment(dataForm[indexCurrent - 1].nghiTungay),
    });
    //console.log(record.index, indexCurrent);
  };

  const save = (datachange) => {
    API.put("/donnghiphep", JSON.stringify(datachange)).then((response) => {
      if (response.data.code === 200) {
        message.success("Cập nhật thành công ");
        setChange(true);
      } else {
        message.warning("Không thành công , " + response.data);
      }
    });
  };

  const checkLeaveDate = (startDate, numberOfDays) => {
    const isOverlap = (date) => {
      return (
        (date.month() === 2 && date.date() === 31 && numberOfDays !== 1) || // Kiểm tra ngày 31/3
        (date.month() === 3 && date.date() === 1 && numberOfDays > 1) // Kiểm tra ngày 1/4 nếu số ngày nghỉ lớn hơn 1
      );
    };

    // Kiểm tra từng ngày trong khoảng nghỉ xem có giao nhau hay không
    for (let i = 0; i < numberOfDays; i++) {
      const currentDate = moment(startDate).add(i, "days");
      if (isOverlap(currentDate)) {
        //console.log("Đơn nghỉ phép giao nhau với ngày 31/3 hoặc 1/4");
        return true;
      }
    }

    console.log(
      "Không có cảnh báo: Đơn nghỉ phép không giao nhau với ngày 31/3 và 1/4"
    );
  };
  const handleOnChangeSoNgayNghi = (e) => {
    //console.log("handleOnChangeSoNgayNghi " + e);
    //let from = moment(record.nghiTungay).format("YYYY-MM-DD");
    // if (checkLeaveDate(from, e) === true) {
    //   message.warning(
    //     "Đơn nghỉ phép giao nhau với ngày 31/3 hoặc 1/4. Hãy tạo 2 đơn,1 đơn nghỉ  đến 31/3 và  1 đơn nghỉ từ 1/4"
    //   );
    // }
    var tam = e;
    if (e < 1) {
      tam = 1;
    }
    if (record.tinhChuNhat === 0) {
      const loadData = async () => {
        let storename = "QLCC_GetSoNgayPhep";
        let from = moment(record.nghiTungay).format("YYYY-MM-DD");
        let param = `${from};${e}`;
        let content = { storename: storename, param: param };

        const response = await API.post("/databy", content);
        if (response.data[0]) {
          setSoNgayPhep(
            " đến  " +
              moment(record.nghiTungay)
                .add(tam - 1, "days")
                .format(dateFormat) +
              ". Số ngày tính phép: " +
              (e - response.data[0].count)
          );
        }
      };
      loadData();
    } else {
      setSoNgayPhep(
        " đến " +
          moment(record.nghiTungay)
            .add(tam - 1, "days")
            .format(dateFormat) +
          ". Tính phép: " +
          tam
      );
    }

    if (Number(e) % 1 === 0) {
      setShowLucnghi(false);
      setRecord({ ...record, soNgaynghi: e, nghiLuc: null });
    } else {
      setShowLucnghi(true);
      setRecord({ ...record, soNgaynghi: e });
    }
  };
  function onChangeDate(date, dateString) {
    if (record.tinhChuNhat === 0) {
      const loadData = async () => {
        let storename = "QLCC_GetSoNgayPhep";
        let from = moment(record.nghiTungay).format("YYYY-MM-DD");
        let param = `${from};${record.soNgaynghi}`;
        let content = { storename: storename, param: param };

        const response = await API.post("/databy", content);
        if (response.data[0]) {
          setSoNgayPhep(
            " đến " +
              moment(date)
                .add(record.soNgaynghi - 1, "days")
                .format(dateFormat) +
              ". Số ngày tính phép: " +
              (record.soNgaynghi - response.data[0].count)
          );
        }
      };
      loadData();
    } else {
      setSoNgayPhep(
        " đến " +
          moment(date)
            .add(record.soNgaynghi - 1, "days")
            .format(dateFormat) +
          ". Số ngày tính phép: " +
          record.soNgaynghi
      );
    }

    setRecord({ ...record, nghiTungay: date });
  }
  function handleChangeLydoNghi(e) {
    setLydoNghiPhep(e.target.value);
  }
  function handleChangenghiLuc(e) {
    setRecord({ ...record, nghiLuc: e });
  }
  function handleChangeSelectA(e) {
    setRecord({ ...record, nguoiBangiaoA: e });
  }
  function handleChangeNhanVienAll(e) {
    e ? setFilter(e) : form.resetFields();
  }
  function handleChangeSelectB(e) {
    setRecord({ ...record, nguoiBangiaoB: e });
  }
  function deleteRecord() {
    if (
      record.signNum >= 1 &&
      !(
        profile.roleName.indexOf("admin") > 0 ||
        profile.roleName.indexOf("nhansu") > 0
      )
    )
      alert(
        "Đơn đã có người duyệt, hãy báo phòng nhân sự để được phê duyệt xóa"
      );
    else {
      setRecord(0);
      save({
        key: record.key,
        _khoa: 2,
        _editByUser: profile.idNhanvien,
      });
      form.resetFields();
      setShowAdd(true);
    }
  }
  const handleChangeChuNhat = (e) => {
    //console.log("handleChangeChuNhat");
    if (e === 0) {
      const loadData = async () => {
        let storename = "QLCC_GetSoNgayPhep";
        let from = moment(record.nghiTungay).format("YYYY-MM-DD");
        let param = `${from};${record.soNgaynghi}`;
        let content = { storename: storename, param: param };
        //   console.log({ content });
        const response = await API.post("/databy", content);
        if (response.data[0]) {
          setSoNgayPhep(
            " đến " +
              moment(record.nghiTungay)
                .add(record.soNgaynghi - 1, "days")
                .format(dateFormat) +
              ". Tính phép: " +
              (record.soNgaynghi - response.data[0].count)
          );
        }
      };
      loadData();
    } else {
      setSoNgayPhep(
        " đến " +
          moment(record.nghiTungay)
            .add(record.soNgaynghi - 1, "days")
            .format(dateFormat) +
          ". Tính phép: " +
          record.soNgaynghi
      );
    }
    setRecord({ ...record, tinhChuNhat: e });
  };
  function add() {
    setLookAllInput(false);
    setShowAdd(false);
    setDisable(true);
    setDataForm(
      dataForm.push({
        idNhanvien: profileInfo[0].idNhanvien,
        key: 0,
        index: 0,
        soNgaynghi: 1,
        loaiNgaynghi: 1,
        nguoiBangiaoB: record.nguoiBangiaoB,
        nguoiBangiaoA: record.nguoiBangiaoA,
        // nghiTungay: moment().add(3, "days"),
        nghiTungay: moment(),
        tinhChuNhat: record.tinhChuNhat,
      })
    );
    setRecord(dataForm[dataForm.length - 1]);
  }

  /**End Functions */

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          subTitle={
            profile.roleName.indexOf("admin") > 0 ? (
              <Select
                style={{ width: "350px" }}
                onChange={handleChangeNhanVienAll}
                showSearch
                value={filter}
                allowClear
                placeholder="Gõ tìm tên NV"
                optionFilterProp="children"
              >
                {nhanvienAll.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            ) : profileInfo[0] ? (
              profileInfo[0].NickName
            ) : (
              ""
            )
          }
          extra={[
            <Space key={1}>
              <Tooltip
                placement="bottomRight"
                title={
                  "Phép tồn chỉ tính cho những ngày dùng loại nghỉ phép năm"
                }
              >
                {" "}
                {thongtinDon[0]
                  ? profileInfo[0]
                    ? "Mã đơn " +
                      record.key +
                      ". Phép " +
                      thongtinDon[0].TongPhep +
                      " Tồn cuối " +
                      (thongtinDon[0].TongPhep - thongtinDon[0].SoNgayTinhPhep)
                    : "" +
                      ", Phép " +
                      thongtinDon[0].TongPhep +
                      " Tồn cuối " +
                      (thongtinDon[0].TongPhep - thongtinDon[0].SoNgayTinhPhep)
                  : ""}
              </Tooltip>
              {showAdd && (
                <Button
                  type="primary"
                  shape="circle"
                  title="Tạo đơn"
                  onClick={add}
                  icon={<FileAddTwoTone />}
                ></Button>
              )}
              {
                <div>
                  {/* Nút để hiển thị hộp thoại Print Preview */}
                  <Button
                    type="secondary"
                    onClick={handleShowPrintPreview}
                    icon={<PrinterOutlined />}
                  />

                  {/* Chỉ hiển thị Modal khi isPrintPreviewOpen là true */}
                  {isPrintPreviewOpen && (
                    <Modal
                      title="In đơn Nghỉ Phép"
                      open={isPrintPreviewOpen}
                      onCancel={handleClosePrintPreview}
                      footer={[
                        <Button key="print" onClick={handlePrint}>
                          In Đơn
                        </Button>,

                        <Button key="cancel" onClick={handleClosePrintPreview}>
                          Đóng
                        </Button>,
                      ]}
                    >
                      <p>
                        <strong>Họ và tên : </strong> {profileInfo[0].NickName}{" "}
                      </p>

                      <p>
                        {" "}
                        <strong>Nghỉ {record.soNgaynghi} ngày, từ </strong>{" "}
                        {moment(record.nghiTungay).format(dateFormat)}
                      </p>
                      <p>
                        {" "}
                        <strong>Mã đơn </strong> {record.key}
                      </p>
                    </Modal>
                  )}
                </div>
              }
            </Space>,
          ]}
        >
          <Steps size="small" current={record.indexSign}>
            {dataFormSignsActive &&
              dataFormSignsActive.map((item, index) => (
                <Step key={index} title={item.tenViTri} />
              ))}
          </Steps>
        </PageHeader>
      </div>
      <Form
        {...formItemLayout}
        form={form}
        name="donnghiphep"
        onFinish={onFinish}
        initialValues={record}
        scrollToFirstError
      >
        <Form.Item
          autoFocus={true}
          layout="vertical"
          name="nghiTungay"
          label="Nghỉ từ ngày"
          style={{ width: "100%" }}
          tooltip="Bắt đầu thực sự nghỉ vào ngày nào"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn ngày bắt đầu nghỉ",
            },
          ]}
        >
          <DatePicker
            disabled={lookAllInput}
            format={dateFormat}
            onChange={onChangeDate}
            placeholder="Chọn ngày nghỉ"
            value={record.nghiTungay}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Số ngày nghỉ"
          name="soNgaynghi"
          tooltip="Tổng số ngày nghỉ thực tế kể luôn cả Chủ nhật hoặc  ngày lễ, phép khác nếu có.."
          rules={[
            {
              required: true,
              message: "Vui lòng điền số ngày nghỉ!",
            },
          ]}
        >
          <InputNumber
            disabled={lookAllInput}
            value={record.soNgaynghi}
            onChange={handleOnChangeSoNgayNghi}
            step="0.5"
            style={{ width: "20%" }}
            min={0.5}
            max={5000}
          />
          <Text type="danger" value={record.denngay}>
            {soNgayPhep}
          </Text>
          <Tooltip placement="bottomRight" title={"Lịch sử ph theo từng đơn"}>
            <Typography.Text className="ant-form-text" type="secondary">
              <IssuesCloseOutlined />

              {record.PhepTonLuyke === 0
                ? ""
                : "Phép còn " + record.PhepTonLuyke}
            </Typography.Text>
          </Tooltip>
        </Form.Item>

        {(showLucnghi || Number(record.soNgaynghi) % 1 !== 0) && (
          <Form.Item
            label="Nghỉ vào buổi nào?"
            name="nghiLuc"
            style={{ width: "100%" }}
            tooltip="Nghỉ vào buổi sáng hay chiều?"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn nghỉ sáng hay chiều!",
              },
            ]}
          >
            <Select placeholder="Chọn buổi" onChange={handleChangenghiLuc}>
              {nghilucs.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="tinhChuNhat"
          label="Tính Chủ nhật"
          tooltip={`Chọn  "Không tính Chủ nhật " khi những ngày nghỉ  có rơi vào ngày  Chủ nhật và hệ thống
            không tính đó là ngày phép ( Thường áp dụng  cho các khoa phòng có lịch làm việc được nghỉ Chủ nhật( khối Vận hành, Thu ngân..v.v )`}
          rules={[
            {
              required: true,
              message: "Phải chọn thông tin có tính ngày Chủ Nhật hay không?",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            onChange={handleChangeChuNhat}
            disabled={lookAllInput}
          >
            {tinhChuNhats.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="loaiNgaynghi"
          label="Loại nghỉ"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn loại ngày nghỉ!",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            onChange={handleChange}
            disabled={lookAllInput}
          >
            {loainghis.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
            <Select.Option value="disabled" disabled>
              Disabled
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="lydoNghi"
          label="Lý do nghỉ"
          onChange={handleChangeLydoNghi}
          rules={[
            {
              required: true,
              message: "Vui lòng điền Lý do nghỉ!",
            },
          ]}
        >
          <Input disabled={lookAllInput} placeholder="Điền vào lý do nghỉ" />
        </Form.Item>

        <Form.Item
          name="nguoiBangiaoA"
          label="Người bàn giao 1"
          rules={[
            {
              required: true,
              message: "Phải chọn ít nhất 1 người bàn giao!",
            },
          ]}
        >
          <Select
            disabled={lookAllInput}
            style={{ width: "100%" }}
            onChange={handleChangeSelectA}
            showSearch
            allowClear
            placeholder="Gõ tìm người bàn giao"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {nhanviensMatch.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="nguoiBangiaoB" label="Người bàn giao 2">
          <Select
            disabled={lookAllInput}
            style={{ width: "100%" }}
            onChange={handleChangeSelectB}
            showSearch
            allowClear
            placeholder="Gõ tìm người bàn giao 2"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {nhanviensMatch.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Space>
            <Button
              disabled={disable4}
              type="primary"
              htmlType="submit"
              shape="circle"
              title="Lưu"
              icon={<SaveOutlined />}
            />
            <Popconfirm
              cancelText="Bỏ qua"
              title="Bạn có chắc muốn xóa đơn không?"
              onConfirm={deleteRecord}
            >
              <Button
                disabled={disable3}
                key={2}
                type="primary"
                shape="circle"
                title="Xóa đơn"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
            <Divider type="vertical" />
            <Button
              key={3}
              disabled={disable}
              onClick={handleOnClickBack}
              type="primary"
              title="Xem đơn cũ hơn"
              shape="circle"
              icon={<StepBackwardOutlined />}
            />
            <Button
              key={4}
              disabled={disable2}
              onClick={handleOnClickForward}
              type="primary"
              title="Xem đơn mới hơn"
              shape="circle"
              icon={<StepForwardOutlined />}
            />
            <Badge
              key={0}
              title="Số thứ tự đơn"
              count={
                disable4 ? (
                  <CheckCircleOutlined style={{ color: "#52c41a" }} />
                ) : (
                  record.key !== 0 && (
                    <ClockCircleOutlined spin style={{ color: "#f5222d" }} />
                  )
                )
              }
            >
              <Avatar
                style={{ backgroundColor: "#52c41a" }}
                shape="circle"
                size="small"
              >
                {record && record.index}
              </Avatar>
            </Badge>
            <Divider type="vertical" />
            <Text italic type="danger">
              {record.taoLuc ? record.trangthaiDon : ""}
            </Text>

            <Text italic type="secondary">
              {record.taoLuc ? " " + record.taoLuc : ""}
            </Text>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
