import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "./../../index.css";
import API from "../../api";

import {
  Table,
  Input,
  Space,
  Modal,
  Button,
  message,
  Typography,
  Tooltip,
} from "antd";
import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  EditOutlined,
  SaveOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  FilterOutlined,
  StopFilled,
  SendOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;
const { Text } = Typography;
const initValue = {
  ghichu: "",
  bangiaoAstate: "",
};
const initRuleName = {
  rule: "",
  active: false,
};

export default function Dasboard() {
  const profile = JSON.parse(localStorage.getItem("auth"));
  const [values, setValues] = useState(initValue);
  const [data, setData] = useState([]); // truyền vào dữ liệu table
  const [dataSearch, setDataSearch] = useState([]); //dữ liệu tìm được
  const [rules, setRule] = useState(initRuleName); //quản lý state rule đang tương tác
  const [modalVisible, setModalVisible] = useState(false); // truyền vào dữ liệu table
  const [value, setValue] = useState("");
  const [xong, setXong] = useState(0);
  const [colorFilter, setColorfilter] = useState("#fc1703");
  const [stateFilter, setStateFilter] = useState("Đơn đang chờ ");

  const [change, setChange] = useState(false);
  // let searchInput;

  /**uesEfect */
  useEffect(() => {
    const loadData = async () => {
      const response = await API.get(`/donnghipheps/?filter=${xong}`);
      let data_nhanvien = response.data.filter(function (da) {
        return (
          da.idNhanvien === profile.idNhanvien ||
          da.idSignWait === profile.idNhanvien
        );
      });
      if (profile.roleName.indexOf("nhansu") > 0) {
        setData(response.data);
        setDataSearch(response.data);
      } else {
        setData(data_nhanvien);
        setDataSearch(data_nhanvien);
      }
    };
    loadData();
    setChange(false);
  }, [change, xong, profile.idNhanvien, profile.roleName]);

  /**functions */

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={(node) => {
  //           searchInput = node;
  //         }}
  //         placeholder={`Tìm ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) => {
  //           setSelectedKeys(e.target.value ? [e.target.value] : []);
  //         }}
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: "block" }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => handleReset(clearFilters)}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             confirm({ closeDropdown: false });
  //             setSearchText(selectedKeys[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]
  //       ? record[dataIndex]
  //           .toString()
  //           .toLowerCase()
  //           .includes(value.toLowerCase())
  //       : "",
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.select(), 100);
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ""}
  //       />
  //     ) : (
  //       text
  //     ),
  // });
  // const customColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => <div>hi</div>,
  // });
  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };
  // const handleReset = (clearFilters) => {
  //   clearFilters();
  //   setSearchText("");
  // };
  const filterData = () => {
    switch (xong) {
      case 0:
        setXong(1);
        setStateFilter("Đơn duyệt đủ ");
        setColorfilter("#35d650");
        break;
      case 1:
        setXong(3);
        setStateFilter("Đơn không được đồng ý ");
        setColorfilter("#fcc603");
        break;
      case 3:
        setXong(0);
        setStateFilter("Đơn chờ duyệt ");
        setColorfilter("#fc1703");
        break;
      default:
        setXong(0);
        break;
    }
  };
  const FilterByNameInput = (
    <Input
      prefix={<SearchOutlined />}
      suffix={
        <Tooltip title="Nhấn vào để lọc ra đơn chưa duyệt hoặc đã duyệt đủ hoặc bị từ chối">
          {stateFilter}
          <FilterOutlined onClick={filterData} style={{ color: colorFilter }} />
        </Tooltip>
      }
      placeholder="Gõ tìm theo tên hoặc khoa phòng"
      value={value}
      onChange={(e) => {
        const currValue = e.target.value;
        setValue(currValue);
        const filteredData = data.filter(
          (entry) =>
            entry.hoten
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase()) ||
            entry.tenphongban
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
        );
        setDataSearch(filteredData);
      }}
    />
  );

  const bangiao = (record, ruleName, id) => {
    setModalVisible(true);
    setValues(record);
    switch (ruleName) {
      case "bangiaoA":
        setRule({ rule: "bangiaoA", active: true, id: id });

        break;
      case "bangiaoB":
        setRule({ rule: "bangiaoB", active: true, id: id });

        break;
      case "tkp":
        setRule({ rule: "tkp", active: true, id: id });

        break;
      case "tpns":
        setRule({ rule: "tpns", active: true, id: id });

        break;
      case "bgd":
        setRule({ rule: "bgd", active: true, id: id });

        break;
      case "ddt":
        setRule({ rule: "ddt", active: true, id: id });

        break;
      case "ddtbv":
        setRule({ rule: "ddtbv", active: true, id: id });

        break;

      default:
    }
  };

  const columns = [
    {
      title: FilterByNameInput,
      dataIndex: "operation",
      render: (_, record) => {
        //console.log("render table");
        const points = record.point;
        let point = [];
        if (points) {
          point = record.point.split(",");
        }

        return !points ? (
          <Space>
            <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
            <Text italic>
              Đơn của {record.hoten} đã ký duyệt đủ, nghỉ từ ngày{" "}
              {record.nghitungay}
            </Text>
            {record.ykienDdt && (
              <Text mark>
                {"ĐDT:  "} {record.ykienDdt}
              </Text>
            )}
            {record.ykienTkp && (
              <Text mark>
                {"Trưởng KP: "} {record.ykienTkp}
              </Text>
            )}
            {record.ykienDdtBv && (
              <Text mark>
                {"ĐDT BV: "} {record.ykienDdtBv}
              </Text>
            )}
            {record.ykienTpns && (
              <Text mark>
                {" TP.Nhân sự:  "} {record.ykienTpns}
              </Text>
            )}
            {record.ykienBgd && (
              <Text mark>
                {"Ban GĐ: "} {record.ykienBgd}
              </Text>
            )}
            {record.logSign && (
              <Text mark>
                {"  "} {record.logSign}
              </Text>
            )}
          </Space>
        ) : (
          <>
            {point.length > 0 && (
              <Space>
                {profile.idNhanvien === record.idSignWait ? (
                  <Space>
                    <Button
                      onClick={() => bangiao(record, point[2], point[0])}
                      type="primary"
                      size="small"
                      icon={<EditOutlined />}
                    >
                      Ký
                    </Button>
                    <Text italic>
                      Đơn của {record.hoten} chờ {point[5]},<SendOutlined />
                      {record.taoluc}
                    </Text>
                    {point[6] === "2" ? (
                      <StopFilled style={{ color: "#f5222d" }} />
                    ) : (
                      ""
                    )}
                  </Space>
                ) : (
                  <Space>
                    <ClockCircleOutlined spin style={{ color: "#f5222d" }} />{" "}
                    <Text italic>
                      Đơn của {record.hoten} chờ {point[5]},{" "}
                      <SendOutlined style={{ color: "#2596be" }} />
                      {record.taoluc}
                    </Text>
                    {point[6] === "2" ? (
                      <StopFilled style={{ color: "#f5222d" }} />
                    ) : (
                      ""
                    )}
                  </Space>
                )}
                {record.ykienDdt && (
                  <Text mark>
                    {"ĐDT:  "} {record.ykienDdt}
                  </Text>
                )}
                {record.ykienTkp && (
                  <Text mark>
                    {"Trưởng KP: "} {record.ykienTkp}
                  </Text>
                )}

                {record.ykienDdtBv && (
                  <Text mark>
                    {"ĐDT BV: "} {record.ykienDdtBv}
                  </Text>
                )}
                {record.ykienTpns && (
                  <Text mark>
                    {" TP.Nhân sự:  "} {record.ykienTpns}
                  </Text>
                )}
                {record.ykienBgd && (
                  <Text mark>
                    {"Ban GĐ: "} {record.ykienBgd}
                  </Text>
                )}
                {record.logSign && (
                  <Text mark>
                    {"  "} {record.logSign}
                  </Text>
                )}
                {profile.roleName.indexOf("nhansu") > 0 ? (
                  <Button
                    onClick={() => bangiao(record, point[2], point[0])}
                    type="primary"
                    size="small"
                    icon={<UnorderedListOutlined />}
                  />
                ) : (
                  ""
                )}
              </Space>
            )}
          </>
        );
      },
    },
  ];

  const cancelModal = () => {
    setModalVisible(false);
  };
  const handleNo = () => {
    save(2);
  };

  const save = (state) => {
    let data = null;

    switch (rules.rule) {
      case "bangiaoA":
        data = {
          ...values,
          _bangiaoAstate: state,
          _ykienbangiaoA: values.ghichu,
        };
        break;
      case "bangiaoB":
        data = {
          ...values,
          _bangiaoBstate: state,
          _ykienbangiaoB: values.ghichu,
        };

        break;
      case "tkp":
        data = {
          ...values,
          _tkpState: state,
          _ykienTkp: values.ghichu,
          _tkpPheduyet: profile.idNhanvien,
        };
        break;
      case "tpns":
        data = {
          ...values,
          _tpnsState: state,
          _ykienTpns: values.ghichu,
          _tpnsPheduyet: profile.idNhanvien,
        };
        break;
      case "bgd":
        data = {
          ...values,
          _bgdState: state,
          _ykienBgd: values.ghichu,
          _bgdPheduyet: profile.idNhanvien,
        };
        break;
      case "ddt":
        data = {
          ...values,
          _ddtState: state,
          _ykienDdt: values.ghichu,
          _ddtPheduyet: profile.idNhanvien,
        };
        break;
      case "ddtbv":
        data = {
          ...values,
          _ddtBvState: state,
          _ykienDdtBv: values.ghichu,
          _ddtBvPheduyet: profile.idNhanvien,
        };
        break;
      default:
        break;
    }
    data = JSON.stringify({
      ...data,
      keyViTri: rules.rule,
      idSignActive: rules.id,
      ok: state,
      ghichuBy: values._ykienTpns,
    });

    // console.log(data);
    API.put(
      `/donnghiphep/?idDonNghiPhep=${values.key}&state=${state}`,
      data
    ).then((response) => {
      if (response.data.code === 200) {
        message.success("Cập nhật thành công ");
        setChange(true);
      } else {
        message.warning("Không thành công , " + response.data);
      }
    });

    setModalVisible(false);
  };
  const onOk = () => {
    save(1);
  };
  const handleNoidung = (e) => {
    setValues({
      ...values,
      _ykienTpns: e.target.value,
    });
  };
  const saveNote = () => {
    let ghichu = values._ykienTpns;

    //console.log(values);
    let data = JSON.stringify({
      key: values.key,
      _ykienTpns: ghichu,
    });
    //console.log(data);

    try {
      API.put(`/donnghiphep`, data).then((response) => {
        if (response.data.code === 200) {
          message.success("Cập nhật thành công ");
          setChange(true);
        } else {
          message.warning("Không thành công , " + response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        centered
        title="Xác nhận ký duyệt"
        style={{ top: 20 }}
        open={modalVisible}
        onCancel={cancelModal}
        footer={[
          <Button key={1} danger onClick={handleNo}>
            Không duyệt
          </Button>,

          <Button key="submit" type="primary" onClick={onOk}>
            Nhấn nút để ký
          </Button>,
        ]}
      >
        <TextArea
          placeholder="Nhập ý kiến hoặc để trống"
          name="ghichu"
          onChange={handleNoidung}
          allowClear
        />
        {profile.roleName.indexOf("nhansu") > 0 ? (
          <Button
            key={4}
            type="primary"
            onClick={saveNote}
            title="Lưu"
            shape="circle"
            icon={<SaveOutlined />}
          />
        ) : (
          ""
        )}
      </Modal>
      <Table
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>
              {record.hoten}, {record.tenphongban}, nghỉ: {record.songaynghi}{" "}
              ngày từ {record.nghitungay} đến {record.nghidenngay} ( Số ngày
              nghỉ tính phép {record.soNgayTinhPhep}, tính chủ nhật :{" "}
              {record.tinhChuNhat} ) , lý do : {record.lydonghi}, gửi vào{" "}
              {record.taoluc}
              {record.BanGiaoA ? ". Bàn giao 1  : " + record.BanGiaoA : ""}
              {record.BanGiaoB ? ". Bàn giao 2 : " + record.BanGiaoB : ""}
              {record.TongPhep
                ? ". Tổng phép năm nay : " + record.TongPhep
                : ""}
              {". Đã dùng " + record.pheptam}
              {". Tồn: " + (record.TongPhep - record.pheptam)}
              {". Loại nghỉ : " + record.loaiNgaynghi} (Mã đơn: {record.key})
            </p>
          ),
          rowExpandable: (record) => record.lydonghi !== null,
        }}
        dataSource={dataSearch}
        columns={columns}
      />
    </>
  );
}
